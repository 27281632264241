import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo.js"
import Title from "../components/title"
import Layout from "../components/layout.js"
import { WPContent } from "../components/globalstyles.js"

const PostTemplate = props => {
  const fluid =
    props.data.post.featured_media &&
    props.data.post.featured_media.localFile.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={props.data.post.title} />
      <Title>{props.data.post.title}</Title>
      {fluid && <StyledImg fluid={fluid} />}
      {props.data.post.content && (
        <WPContent
          dangerouslySetInnerHTML={{ __html: props.data.post.content }}
        />
      )}
    </Layout>
  )
}

const StyledImg = styled(Img)`
  max-width: 600px;
`

export default PostTemplate

export const PostTemplateQuery = graphql`
  query($wpId: Int!) {
    post: wordpressPost(wordpress_id: { eq: $wpId }) {
      link
      title
      content
      slug
      wordpress_id
      excerpt
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 960) {
              aspectRatio
              base64
              originalImg
              originalName
              presentationHeight
              presentationWidth
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
  }
`
